import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@fundo/app/services/auth.service';
import { updateUserIp } from '@fundo/app/states/user/actions/user.action';
import { environment } from '@fundo/environments/environment';
import { Store } from '@ngrx/store';
import { catchError, first, map, Observable, of, switchMap, tap, timeout } from 'rxjs';

interface IpWrapper {
  ip: string;
}

type IpifyResponse = IpWrapper;

interface LocationAllowedResponse {
  errorKey: number;
  result: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class IpInfoService {
  private readonly API_BASE = environment.apiBaseUrl;

  constructor(
    private httpClient: HttpClient,
    private readonly authService: AuthService,
    private store: Store,
  ) {}

  /**
   * Get information about current IP address
   * @returns IP address information
   */
  getIpInfo(): Observable<string> {
    const timeoutSeconds = 5000;
    return this.httpClient.get<IpifyResponse>('https://api.ipify.org?format=json').pipe(
      timeout(timeoutSeconds),
      map((response: IpifyResponse) => response.ip),
      tap((ip: string) => this.store.dispatch(updateUserIp({ ip }))),
      catchError(() => of('')),
    );
  }

  /**
   * Use service to validate the IP
   * @returns if the IP provided is allowed
   */
  validateIp(ip: string): Observable<boolean> {
    return this.httpClient
      .post<any>(`${this.API_BASE}/lead/locationAllowed`, {
        ip,
      })
      .pipe(
        map((response: LocationAllowedResponse) => {
          const { errorKey, result } = response || {};
          return errorKey ? true : result;
        }),
        catchError(() => of(true)),
      );
  }

  /**
   * Request the user's IP and use service to validate the IP
   * @returns if the IP provided is allowed
   */
  requestIpThenValidate(): Observable<boolean> {
    return this.getIpInfo().pipe(
      first(),
      switchMap((ip: string) => this.validateIp(ip)),
    );
  }

  /**
   * Request token, the user's IP and use service to validate the IP
   * @returns if the IP provided is allowed
   */
  validateIpWithToken(): Observable<boolean> {
    return this.authService
      .getAuthorizationToken()
      .pipe(switchMap(() => this.requestIpThenValidate()));
  }
}
